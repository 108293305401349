import { React, Page } from 'lib'
import PremiumPage from './_becon+'

function BeconPlus() {
  return (
    <Page whiteBackground>
      <PremiumPage/>
    </Page>
  )
}

export default BeconPlus
